/*
  Auth
*/
// Login
const LOGIN = '/auth/login';
const LOGOUT = '/auth/logout';
const GET_HIERARCHY_MENUS = '/auth/get-hierarchy-menus';

// Banned Trade Request
const QUERY_BANNED_TRADE_REQUEST = '/banned-trade/banned-trade-request-list';
const APPROVE_BANNED_TRADE_REQUEST = '/banned-trade/approve-banned-trade';
const REJECT_BANNED_TRADE_REQUEST = '/banned-trade/reject-banned-trade';
const FEEDBACK_BANNED_TRADE_REQUEST = '/banned-trade/feedback-banned-trade';
const UPDATED_BANNED_TRADE_REQUEST = '/banned-trade/updated-banned-trade-for-manager';

// Personal Trade
const QUERY_PERSONAL_TRADE = '/personal-trade/submitted-list';

const APPROVE_PERSONAL_TRADE = '/personal-trade/approve-personal-trade';
const FINAL_APPROVE_PERSONAL_TRADE = '/personal-trade/final-approve-personal-trade';
const REJECT_PERSONAL_TRADE = '/personal-trade/reject-personal-trade';

const FEEDBACK_PERSONAL_TRADE = '/personal-trade/feedback-personal-trade';
const QUERY_PERSONAL_TRADE_OPERATE_RECORD = '/personal-trade/personal-trade-operate-record-list'
const PERSONAL_TRADE_FIELD_HISTORY = '/personal-trade/personal-trade-field-history';

const NEW_PERSONAL_TRADE = '/personal-trade/new-personal-trade-by-admin';
const UPDATED_PERSONAL_TRADE = '/personal-trade/updated-personal-trade-by-admin';
const BATCH_DELETED_PERSONAL_TRADES = '/personal-trade/batch-deleted-personal-trade-by-admin';
const COPIED_PERSONAL_TRADE = '/personal-trade/copied-personal-trade-by-admin';
const RECOGNIZE_PERSONAL_TRADE_REQUEST = '/personal-trade/recognize-personal-trade-request';

const LOAD_MONTHLY_REPORT = '/personal-trade/pa-dealing-monthly-report';
const SEND_PA_DEALING_APPLY_REPORT_EMAIL = '/personal-trade/pa-dealing-monthly-report-email';
const EXPORT_PA_DEALING_DAILY_REPORT = '/personal-trade/export-pa-dealing-daily-report';

// 附件操作
const UPLOAD_PERSONAL_TRADE_ATTACHMENT = '/personal-trade/personal-trade-attachment';
const PREVIEW_PERSONAL_TRADE_ATTACHMENT = '/personal-trade/preview-personal-trade-attachment';
const DOWNLOAD_PERSONAL_TRADE_ATTACHMENT = '/personal-trade/download-personal-trade-attachment';

// Personal Holding
const QUERY_PERSONAL_HOLDING = '/personal-holding/personal-holding';
const APPROVE_PERSONAL_HOLDING = '/personal-holding/approve-personal-holding';
const REJECT_PERSONAL_HOLDING = '/personal-holding/reject-personal-holding';
const FEEDBACK_PERSONAL_HOLDING = '/personal-holding/feedback-personal-holding';

const NEW_PERSONAL_HOLDING_REPORT = '/personal-holding/new-personal-holding-report';
const UPDATED_PERSONAL_HOLDING_REPORT = '/personal-holding/updated-personal-holding-report';
const BATCH_DELETED_PERSONAL_HOLDING_REPORTS = '/personal-holding/batch-deleted-personal-holding-reports';

// Personal Account
const QUERY_PERSONAL_ACCOUNT_LIST = '/personal-account/query-personal-account-list';
const PERSONAL_ACCOUNT_DOWNLOAD_URL = '/personal-account/personal-account-download-url';
const PERSONAL_ACCOUNT_UPLOAD_URL = '/personal-account/personal-account-upload-url';
const SUBMIT_ADD_OR_EDIT_ACCOUNT = '/personal-account/add-or-edit-personal-account';
const DEL_PERSONAL_ACCOUNT = '/personal-account/del-personal-account';
const CHANGE_PERSONAL_ACCOUNT_STATUS = '/personal-account/change-personal-account-status';
const SUBMIT_ADD_OR_EDIT_HOLDING = '/personal-holding/submit-add-or-edit-holding';
const DEL_PERSONAL_HOLDING = '/personal-holding/del-personal-holding';


// 管理端新增/更新PersonalAccount
const NEW_PERSONAL_ACCOUNT_ON_ADMIN_END = '/personal-account/new-personal-account-on-admin-end';
const UPDATED_PERSONAL_ACCOUNT_ON_ADMIN_END = '/personal-account/updated-personal-account-on-admin-end';
const BATCH_DELETE_PERSONAL_ACCOUNT_ON_ADMIN_END = '/personal-account/batch-deleted-personal-account-on-admin-end';

// PersonalAccount 的提交历史
const PERSONAL_ACCOUNT_FIELD_HISTORY = '/personal-account/personal-account-field-history';

// 管理端新增/更新PersonalHolding
const NEW_PERSONAL_HOLDING_ON_ADMIN_END = '/personal-holding/new-personal-holding-on-admin-end';
const UPDATED_PERSONAL_HOLDING_ON_ADMIN_END = '/personal-holding/updated-personal-holding-on-admin-end';
const BATCH_DELETE_PERSONAL_HOLDING_ON_ADMIN_END = '/personal-holding/batch-deleted-personal-holding-on-admin-end';

// 接受/拒绝 PersonalAccountRequest
const ACCEPT_PERSONAL_ACCOUNT_REQUEST = '/personal-account/accepted-personal-account-request';
const REJECT_PERSONAL_ACCOUNT_REQUEST = '/personal-account/rejected-personal-account-request';

// 邮件发送记录
const ACCOUNT_REPORT_PLAN_RECORD_LIST = '/personal-account/account-report-plan-record-list';

// Account Holding Report Plan
const QUERY_ACCOUNT_HOLDING_REPORT_PLAN = '/account-holding-report-plan/list';
const NEW_ACCOUNT_HOLDING_REPORT_PLAN = '/account-holding-report-plan/new-account-holding-report-plan';
const UPDATED_ACCOUNT_HOLDING_REPORT_PLAN = '/account-holding-report-plan/updated-account-holding-report-plan';
const BATCH_DELETED_ACCOUNT_HOLDING_REPORT_PLANS = '/account-holding-report-plan/batch-deleted-account-holding-report-plans';


// Gift Hospitality Request
const QUERY_GIFT_HOSPITALITY_REQUEST = '/gift-hospitality/submitted-list';
const APPROVE_GIFT_HOSPITALITY_REQUEST = '/gift-hospitality/approve-gift-hospitality-request';
const REJECT_GIFT_HOSPITALITY_REQUEST = '/gift-hospitality/reject-gift-hospitality-request';
const FEEDBACK_GIFT_HOSPITALITY_REQUEST = '/gift-hospitality/feedback-gift-hospitality-request';
const UPLOAD_GIFT_HOSPITALITY_ATTACHMENT = '/gift-hospitality/gift-hospitality-attachment';
const DOWNLOAD_GIFT_HOSPITALITY_ATTACHMENT = '/gift-hospitality/download-gift-hospitality-attachment';

/*
  Compliance Rule
*/

// Restricted Security Request
const LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST = '/restricted-security-request/submitted-restricted-security-request';
const APPROVE_RESTRICTED_SECURITY_REQUEST = '/restricted-security-request/approve-restricted-security-request';
const REJECT_RESTRICTED_SECURITY_REQUEST = '/restricted-security-request/reject-restricted-security-request';
const FEEDBACK_RESTRICTED_SECURITY_REQUEST = '/restricted-security-request/feedback-restricted-security-request';
const LOAD_RESTRICTED_SECURITY_OPERATION_RECORD = '/restricted-security-request/restricted-security-operation-record';
const UPDATE_RESTRICTED_SECURITY_REQUEST = '/restricted-security-request/update-restricted-security-request-by-admin';
const ADD_RESTRICTED_SECURITY_REQUEST = '/restricted-security/new-restricted-security';

// Restricted Security
const LOAD_SUBMITTED_RESTRICTED_SECURITIES = '/restricted-security/submitted-restricted-securities';
const NEW_RESTRICTED_SECURITY_BY_ADMIN = '/restricted-security/new-restricted-security-by-admin';
const UPDATE_RESTRICTED_SECURITY_BY_ADMIN = '/restricted-security/updated-restricted-security-by-admin';
const BATCH_DELETE_RESTRICTED_SECURITY_BY_ADMIN = '/restricted-security/batch-deleted-restricted-securities-by-admin';

const ON_LIST_RESTRICTED_SECURITY_BY_ADMIN = '/restricted-security/on-list-restricted-security-by-admin';
const OFF_LIST_RESTRICTED_SECURITY_BY_ADMIN = '/restricted-security/off-list-restricted-security-by-admin';

// Restricted Security Attachment
const UPLOAD_RESTRICTED_SECURITY_ATTACHMENT = '/restricted-security/restricted-security-attachment';
const PREVIEW_RESTRICTED_SECURITY_ATTACHMENT = '/restricted-security/preview-restricted-security-attachment';
const DOWNLOAD_RESTRICTED_SECURITY_ATTACHMENT = '/restricted-security/download-restricted-security-attachment';

// Related Company Price Report
const GENERATE_RELATED_COMPANY_PRICE_REPORT = '/restricted-security/generate-related-comp-price-report';
const LOAD_RELATED_COMPANY_PRICE_REPORT = '/restricted-security/related-comp-price-report';

// Restricted Rule
const QUERY_RESTRICTED_RULES = '/restricted-rule/compliance-rules';
const NEW_RESTRICTED_RULE = '/restricted-rule/new-compliance-rule';
const UPDATED_RESTRICTED_RULE = '/restricted-rule/updated-compliance-rule';
const BATCH_DELETED_RESTRICTED_RULES = '/restricted-rule/batch-deleted-compliance-rule';


// Restricted Asset
const QUERY_RESTRICTED_ASSET = '/compliance-rule/restricted-asset';
const NEW_RESTRICTED_ASSET = '/compliance-rule/new-restricted-asset';
const UPDATED_RESTRICTED_ASSET = '/compliance-rule/updated-restricted-asset';
const DELETED_RESTRICTED_ASSET = '/compliance-rule/deleted-restricted-asset';
const BATCH_DELETED_RESTRICTED_ASSET = '/compliance-rule/batch-deleted-restricted-asset';


// Restricted Country
const QUERY_RESTRICTED_COUNTRIES = '/compliance-rule/restricted-countries';
const NEW_RESTRICTED_COUNTRY = '/compliance-rule/new-restricted-country';
const UPDATED_RESTRICTED_COUNTRY = '/compliance-rule/updated-restricted-country';
const DELETED_RESTRICTED_COUNTRY = '/compliance-rule/deleted-restricted-country';
const BATCH_DELETED_RESTRICTED_COUNTRY = '/compliance-rule/batch-deleted-restricted-countries';


// Restricted Sector
const QUERY_RESTRICTED_SECTORS = '/compliance-rule/restricted-sectors';
const NEW_RESTRICTED_SECTOR = '/compliance-rule/new-restricted-sector';
const UPDATED_RESTRICTED_SECTOR = '/compliance-rule/updated-restricted-sector';
const DELETED_RESTRICTED_SECTOR = '/compliance-rule/deleted-restricted-sector';
const BATCH_DELETED_RESTRICTED_SECTOR = '/compliance-rule/batch-deleted-restricted-sectors';

// SFC Short Interest
const QUERY_SFC_SHORT_INTEREST = '/compliance-rule/sfc-short-interest';

// PB Short Interest Rate
const QUERY_PB_SHORT_INTEREST_RATE = '/compliance-rule/pb-short-interest';

/*
  Questionnaire
*/
const QUERY_QUESTIONNAIRES = '/questionnaire/questionnaires';
const QUERY_QUESTIONS = '/questionnaire/questions';
const QUERY_ANSWERS = '/questionnaire/answers';
const SELECT_ANSWEREES = '/questionnaire/questionnaire-answeree';
const QUERY_TODO_QUESTIONNAIRE = '/questionnaire/to-do-questionnaire';
const SUBMIT_QUESTIONNAIRE_ANSWER = '/questionnaire/questionnaire-answer-submit';
const SAVE_QUESTIONNAIRE_ANSWER = '/questionnaire/questionnaire-answer-hold';

const NEW_QUESTIONNAIRE = '/questionnaire/new-questionnaire';
const UPDATED_QUESTIONNAIRE = '/questionnaire/updated-questionnaire';
const COPIED_QUESTIONNAIRE = '/questionnaire/copied-questionnaire';
const DELETED_QUESTIONNAIRE = '/questionnaire/deleted-questionnaire';
const BATCH_DELETED_QUESTIONNAIRE = '/questionnaire/batch-deleted-questionnaire';

const NEW_QUESTION = '/questionnaire/new-question';
const UPDATED_QUESTION = '/questionnaire/updated-question';
const DELETED_QUESTION = '/questionnaire/deleted-question';
const BATCH_DELETED_QUESTION = '/questionnaire/batch-deleted-question';

const UPLOAD_QUESTIONNAIRE_ATTACHMENT = '/questionnaire/questionnaire-attachment';
const DOWNLOAD_QUESTIONNAIRE_ATTACHMENT = '/questionnaire/download-questionnaire-attachment';

const LOAD_QUESTIONNAIRE_RESULTS = '/questionnaire/questionnaire-results';
const LOAD_QUESTIONNAIRE_RESULT_DETAIL = '/questionnaire/questionnaire-result-detail';
const REJECT_QUESTIONNAIRE_RESULT = '/questionnaire/reject-questionnaire-result';
const ACCEPT_QUESTIONNAIRE_RESULT = '/questionnaire/accept-questionnaire-result';
const REMIND_QUESTIONNAIRE_EMAIL = '/questionnaire/remind-email';
const UPDATE_QUESTIONNAIRE_SCORE = '/questionnaire/update-questionnaire-score';
const LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY = '/questionnaire/submitted-questionnaire-history';



const QUERY_LICENSINGFORM = '/licensingForm/licensingForm';
// const QUERY_QUESTIONS = '/questionnaire/questions';
// const QUERY_ANSWERS = '/questionnaire/answers';
// const SELECT_ANSWEREES = '/questionnaire/questionnaire-answeree';
const QUERY_TODO_LICENSINGFORM = '/licensingForm/to-do-licensingForm';
const SUBMIT_LICENSINGFORM_ANSWER = '/licensingForm/licensingForm-answer-submit';
const SAVE_LICENSINGFORM_ANSWER = '/licensingForm/licensingForm-answer-hold'; 


const NEW_LICENSINGFORM = '/licensingForm/new-licensingForm';
const UPDATED_LICENSINGFORM = '/licensingForm/updated-licensingForm';
const COPIED_LICENSINGFORM = '/licensingForm/copied-licensingForm';
const DELETED_LICENSINGFORM = '/licensingForm/deleted-licensingForm';
const BATCH_DELETED_LICENSINGFORM = '/licensingForm/batch-deleted-licensingForm';

const NEW_ITEM = '/licensingForm/new-item';
const UPDATED_ITEM  = '/licensingForm/updated-question';
const BATCH_DELETED_ITEM = '/licensingForm/batch-deleted-question';



// Entity Account
const QUERY_FUND_LIST = '/entity-account/fund-list';
const QUERY_BOOK_LIST = '/entity-account/book-list';
const QUERY_COUNTERPARTY_LIST = '/entity-account/counterparty-list';
const QUERY_CUSTODIAN_LIST = '/entity-account/custodian-list';
const QUERY_CUSTODIAN_ACCOUNT_LIST = '/entity-account/custodian-account-list';
const LOAD_USER_ACCESS_INFO = '/entity-account/user-access-list';
const ADD_OR_EDIT_USER_ACCESS = '/entity-account/addOrEditUserAccess';
const COP_ALL_USER_ACCESS = '/entity-account/copAllUserAccess';
const DEL_USER_ACCESS = '/entity-account/delUserAccess';
const USER_ACCESS_UPLOAD_URL = '/entity-account/upload-user-access';
const USER_ACCESS_DOWNLOAD_URL = '/entity-account/download-user-access';
const MEETING_AND_TRAINING_DOWNLOAD_URL = '/pinpoint-college/download-meetingAndTraining';
const MEETING_AND_TRAINING_UPLOAD_URL = '/pinpoint-college/upload-meetingAndTraining';




const LOAD_RATING_MANAGEMENT_INFO = '/pinpoint-college/select-rating-list';
const ADD_OR_EDIT_RATING_MANAGEMENT = '/pinpoint-college/addOrEditRating';
const COP_ALL_RATING_MANAGEMENT = '/entity-account/copAllUserAccess';
const DEL_RATING_MANAGEMENT = '/pinpoint-college/delRatingInfo';
const RATING_MANAGEMENT_UPLOAD_URL = '/entity-account/upload-user-access';
const RATING_MANAGEMENT_DOWNLOAD_URL = '/entity-account/download-user-access';

/*
  Reference Data
*/
// User Account
const QUERY_USER_ACCOUNTS = '/user-account/user-accounts';

// Fund Book Data
const QUERY_FUND_BOOK_DATA = '/reference-data/fund-book-data';

/*
  Security
*/
const QUERY_SECURITY_TICKER = '/security/security-ticker';
const QUERY_BBG_TICKERS = '/security/bbg-tickers';
const QUERY_ALL_BBG_TICKERS = '/security/all-bbg-tickers';
const QUERY_ASSET_INST_CLASS = '/security/asset-inst-class';
const QUERY_COUNTRY_CODES = '/security/country-codes';
const QUERY_SECTOR_CODES = '/security/sector-codes';
const QUERY_EXCHANGE_CODE = '/security/exchange-codes';

const QUERY_CURRENCY_CODES = '/security/currency-codes';

/*
  Short Stock
*/
const LOAD_SHORT_STOCK = '/hkex-short-stock/short-stock';
const SYNC_SHORT_STOCK = '/hkex-short-stock/sync-latest-short-stock';

/*
  Exchange Rate
*/
const LOAD_EXCHANGE_RATE_LIST = '/hkex-exchange-rate/list';
const SYNC_EXCHANGE_RATE = '/hkex-exchange-rate/date-specified-exchange-rate';

/*
  13F Report
*/
const LOAD_13F_REPORT_LIST = '/13f-report/list';
const SYNC_13F_REPORT = '/13f-report/sync-13f-report';

/*
  13F Long Position Report
*/
const LOAD_13F_LONG_POS_REPORT = '/13f-long-pos-report/list';
const GENERATE_13F_LONG_POS_REPORT = '/13f-long-pos-report/generate-report';
const CHECK_13F_LONG_POS_REPORT = '/13f-long-pos-report/chk-report-result';
const REPORT_13F_LONG_POS_REPORT = '/13f-long-pos-report/report-13f-long-pos-report';
const REPORT_13F_LONG_POS_SUMMARY_REPORT = '/13f-long-pos-report/report-13f-long-pos-summary-report';
const DOWNLOAD_13F_LONG_POS_REPORT = '/13f-long-pos-report/download-13f-long-pos-report-file/';

/*
  Short Position Report
*/
const LOAD_SHORT_POSITION_REPORT = '/short-pos-report/list';
const GENERATE_SHORT_POSITION_REPORT = '/short-pos-report/generate-report';
const DOWNLOAD_SHORT_POSITION_REPORT = '/short-pos-report/download-short-pos-report-csv-file/';
const SHORT_POSITION_REPORT_CHECK_RESULT = '/short-pos-report/chk-report-result';
const REPORT_SHORT_POSITION_REPORT = '/short-pos-report/report-short-pos-report';

/*
  Fund Level Report
*/
const LOAD_FUND_LEVEL_REPORT = '/fund-level-report/list';
const LIST_FUND_POS_GROUP_INFO = '/fund-level-report/list-fund-position-group-info';
const GENERATE_FUND_LEVEL_REPORT = '/fund-level-report/generate-report';

/*
  Trades Review
*/
const QUERY_TRADES_REVIEW = '/fund-monitoring/query-trades-review';
const QUERY_TRADES_REVIEW_REPORT = '/fund-monitoring/query-trades-review-report';


// download pdf
const DOWNLOAD_QUESTIONNAIRE_RESULT_PDF = '/questionnaire/download-questionnaire-result-pdf';

// 30天持仓相关预检查
const QUERY_30_DAY_HOLDING_CHECK_INFO = '/personal-trade/personal-trade-presubmit-check-info';

// licensing
const LOAD_LICENSING_INFO = '/licensing/load-licensing-info';
const LICENSING_ATT_DOWNLOAD_URL = '/licensing/licensing-att-download-url';
const LOAD_LICENSE_BASIC_INFO_OPTIONS = '/licensing/load-license-basic-info-options';
const LICENSING_EXAM_CPT_PROOF_UPLOAD_URL = '/licensing/upload-licensing-exam-cpt-proof';
const ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD = '/licensing/add-or-edit-license-emp-required-rcd';
const BATCH_DEL_LICENSE_INFO = '/licensing/batch-del-license-info';
const SUBMIT_EDIT_CPT_RCD = '/licensing/submit-edit-cpt-rcd';
const QUERY_OPERATION_RCD = '/licensing/query-operation-rcd';

// Expense Summary
const LOAD_EXPENSE_SUMMARY_INFO = '/expense-summary/load-expense-summary-info';
const ADD_OR_EDIT_EXPENSE_SUMMARY = '/expense-summary/add-or-edit-expense-summary';
const DEL_EXPENSE_SUMMARY = '/expense-summary/del-expense-summary';
const LOAD_EXPENSE_SUMMARY_INFONEW = '/expense-summary/load-expense-summary-info-new';

// Reporting Summary
const LOAD_REPORTING_SUMMARY_INFO = '/reporting-summary/query-reporting-summary';
const INSERT_REPORTING_SUMMARY = '/reporting-summary/insert-reporting-summary';
const UPDATE_REPORTING_SUMMARY = '/reporting-summary/update-reporting-summary';
const BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY = '/reporting-summary/batch-change-status-for-reporting-summary';
const FINISH_REPORTING_SUMMARY_TASK = '/reporting-summary/finish-reporting-summary-task';

//Work Management
const LOAD_WORKING_MANAGEMENT_INFO = '/work-management/query-work-management';
const INSERT_WORKING_MANAGEMENT = '/work-management/insert-work-management';
const UPDATE_WORKING_MANAGEMENT = '/work-management/update-work-management';
const DEL_WORKING_MANAGEMENT = '/work-management/del-work-management';
// const BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT = '/work-management/batch-change-status-for-work-management'; /del-work-management
const BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT = '/work-management/del-work-management'; 
const FINISH_WORKING_MANAGEMENT_TASK = '/work-management/finish-work-management-task';

const INSERT_WORKING_MANAGEMENTSUB = '/work-management/insert-work-managementSubtasks';
const UPDATE_WORKING_MANAGEMENTSUB = '/work-management/update-work-managementSubtasks';

//Portfolio Monitoring Rule
const LOAD_PORTFOLIO_MONITORING_RULE_INFO = '/portfolio-monitoring-rule/query-portfolio-monitoring-rule';
const INSERT_PORTFOLIO_MONITORING_RULE = '/portfolio-monitoring-rule/insert-portfolio-monitoring-rule';
const UPDATE_PORTFOLIO_MONITORING_RULE = '/portfolio-monitoring-rule/update-portfolio-monitoring-rule';
const DEL_PORTFOLIO_MONITORING_RULE = '/portfolio-monitoring-rule/del-portfolio-monitoring-rule';
// const BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT = '/work-management/batch-change-status-for-work-management'; /del-work-management
const BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE = '/portfolio-monitoring-rule/del-portfolio-monitoring-rule'; 
const FINISH_PORTFOLIO_MONITORING_RULE_TASK = '/portfolio-monitoring-rule/finish-portfolio-monitoring-rule-task';


// commons
const GET_FX_BY_NEAREST_DATE = '/fx-rate/get-fx-by-nearest-date';
const QUERY_OPERATION_RECORD = '/common-operation-rcd/query-operation-record';
const QUERY_HISTORY_UPLOAD = '/common-history-upload/query-history-upload';

// pendingTasks
const QUERY_PENDING_TASKS_NUM = '/pending-tasks/query-pending-tasks-num';
const QUERY_PENDING_TASKS_LIST = '/pending-tasks/query-pending-tasks-list';
const IGNORE_DEIGNORE_PENDING_TASKS = '/pending-tasks/ignore-deignore-pending-tasks';

// General Agreement
const LOAD_GENERAL_AGREEMENT_INFO = '/general-agreement/query-agreement-info';
const GENERAL_AGREEMENT_UPLOAD_URL = '/general-agreement/upload-agreement-attachment';
const GENERAL_AGREEMENT_DOWNLOAD_URL = '/general-agreement/download-agreement-attachment';
const INSERT_AGREEMENT_INFO = '/general-agreement/insert-agreement-info';
const UPDATE_AGREEMENT_INFO = '/general-agreement/update-agreement-info';
const SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS = '/general-agreement/update-agreement-info-status';

// Pinpoint Group - Group Structure
const QUERY_GROUP_STRUCTURE = '/group-structure/query-group-structure';
const GROUP_STRUCTURE_DOWNLOAD_URL = '/group-structure/download-group-structure-chart';
const GROUP_STRUCTURE_UPLOAD_URL = '/group-structure/upload-group-structure-chart';
const SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE = '/group-structure/add-or-edit-group-structure';
const SUBMIT_DEL_GROUP_STRUCTURE = '/group-structure/del-group-structure';

// Pinpoint Group - Element Type Manage
const QUERY_ELEMENT_TYPE = '/pinpoint-element-structure/query-element-type';
const SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE = '/pinpoint-element-structure/add-or-edit-element-type';
const DEL_ELEMENT_TYPE = '/pinpoint-element-structure/del-element-type';
const SUBMIT_ADD_OR_EDIT_FIELD = '/pinpoint-element-structure/add-or-edit-field';
const DEL_ELEMENT_TYPE_FIELD = '/pinpoint-element-structure/del-element-type-field';

// Pinpoint Group - Element Manage
const QUERY_ELEMENT = '/pinpoint-element-structure/query-element';
const SUBMIT_ADD_OR_EDIT_ELEMENT = '/pinpoint-element-structure/add-or-edit-element';
const DEL_ELEMENT = '/pinpoint-element-structure/del-element';
const DEL_PROPS = '/pinpoint-element-structure/del-props';

// Monthly Report 
const LOAD_MONTHLY_REPORT_LIST = '/monthly-report/load-report-list';
const LOAD_MONTHLY_REPORT_DETAIL = '/monthly-report/load-report-detail';
const SAVE_MONTHLY_REPORT = '/monthly-report/save';
const DEL_MONTHLY_REPORT = '/monthly-report/remove';
const MONTHLY_REVIEW_UPLOAD_ATTACHMENT = '/monthly-report/upload-attachment';
const MONTHLY_REVIEW_DOWNLOAD_ATTACHMENT = '/monthly-report/download-attachment';

//restricted rule
const LOAD_RESTRICTED_SECURITIES_RULE = '/restricted-security-rule/loadRestrictedRuleList';
const ADD_RESTRICTED_SECURITIES_RULE = '/restricted-security-rule/addRestrictedRule';
const SAVE_RESTRICTED_SECURITIES_RULE = '/restricted-security-rule/updateRestrictedRule';


const LOAD_MEETING_AND_TRAINING_INFO = '/pinpoint-college/meetingAndTraining-list';
const ADD_OR_EDIT_MEETING_AND_TRAINING = '/pinpoint-college/addOrEditMeetingAndTraining';
const COP_ALL_MEETING_AND_TRAINING = '/pinpoint-college/copAllMeetingAndTraining';
const DEL_MEETING_AND_TRAINING = '/pinpoint-college/delMeetingAndTraining';
const SELECT_MEETINGANSWEREES = '/pinpoint-college/select_meetinganswerees';
const UPDATED_NOTE = '/pinpoint-college/update_note';

const LOAD_MEETING_AND_TRAINING_INFO_SUB = '/pinpoint-college/meetingAndTrainingsub-list';
const LOAD_MEETING_AND_TRAINING_INFO_SUB_FORIN = '/pinpoint-college/meetingAndTrainingsubForIndividual-list';
const QUERY_MEETINGANDTRANINGSUB = '/pinpoint-college/query-meetingAndTrainingsub';
const SUBMIT_MEETINGANDTRANINGSUB_ANSWER = '/pinpoint-college/sumit-meetingAndTrainingsub';
const LOAD_MEETING_AND_TRAINING_INFOTO_SUB = '/pinpoint-college/meetingAndTrainingsubOne-list';
const LOAD_MEETING_AND_TRAINING_INFO_SUB_BY_MEETINGID = '/pinpoint-college/meetingAndTrainingsubByMeetingId-list';
const LOAD_ROTATION_SCORE_FORIN = '/pinpoint-college/select-rotation-score-list';
const UPDATE_ROTATION_SCORE_FORIN = '/pinpoint-college/update-rotation-score';

const LOAD_MEETING_AND_TRAINING_CALENDER_PINPOINT = '/pinpoint-college-calendar/pinpoint-calendar-list';
const LOAD_MEETING_AND_TRAINING_CALENDER_PERSONAL = '/pinpoint-college-calendar/calendar-list';
const LOAD_MEETING_AND_TRAINING_CALENDER_LIST_BROKER = '/pinpoint-college-calendar/calendar-list-broker';
const LOAD_MEETING_AND_TRAINING_CALENDER_UPDATE = '/pinpoint-college-calendar/updateEventById';


const LOAD_MEETING_AND_TRAINING_CALENDER_DETAILE = '/pinpoint-college-calendar/getEventDetaileByBroker-list';
const SALE_MEETINGANDTRANINGS = '/pinpoint-college/sale-meetingAndTrainings';

const LOAD_MEETING_AND_TRAINING_GROUP_INFO = '/pinpoint-college/meetingAndTrainingGroup-list';
const ADD_OR_EDIT_MEETING_AND_TRAINING_GROUP = '/pinpoint-college/addOrEditMeetingAndTrainingGroup';
const DEL_MEETING_AND_TRAINING_GROUP = '/pinpoint-college/delMeetingAndTrainingGroup';
const LOAD_MEETING_AND_TRAINING_APPLYRECORD_INFO = '/pinpoint-college/apply_record-list';
const UPDATE_MEETING_AND_TRAINING_APPLYRECORD = '/pinpoint-college/update-applyRecord';



const LOAD_PRESONAL_INFO_INFO = '/licensingPersonalInfo/queryLicensingPersonalInfolist';
const INSERT_PRESONAL_INFO = '/licensingPersonalInfo/addOrEditLicensingPersonalInfo';
const UPDATE_PRESONAL_INFO = '/licensingPersonalInfo/addOrEditLicensingPersonalInfo';
const DEL_PRESONAL_INFO = '/licensingPersonalInfo/meetingAndTrainingGroup-list';
const BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO = '/licensingPersonalInfo/updatePersonalInfoStatus';

const REJECT_PRESONAL_INFO_RESULT = '/licensingForm/reject-licensingForm-result';
const ACCEPT_PRESONAL_INFO_RESULT = '/licensingForm/accept-licensingForm-result';
const REMIND_PRESONAL_INFO_EMAIL = '/licensingForm/remind-email';

const LOAD_REGISTER_INFO = '/licensingRegister/queryLicensingRegisterlist';
const INSERT_REGISTER = '/licensingRegister/addOrEditLicensingRegister';
const UPDATE_REGISTER = '/licensingRegister/addOrEditLicensingRegister';
const DEL_REGISTER = '/licensingRegister/meetingAndTrainingGroup-list';
const BATCH_CHANGE_STATUS_FOR_REGISTER = '/licensingRegister/updateRegisterStatus';

const SELECT_REGISTERINFO_BY_NAME ='/licensingCpt/selectRegisterInfoByName';

const LOAD_CPT_INFO = '/licensingCpt/queryLicensingCptlist';
const INSERT_CPT = '/licensingCpt/addOrEditLicensingCpt';
const UPDATE_CPT = '/licensingCpt/addOrEditLicensingCpt';
const DEL_CPT = '/licensingCpt/meetingAndTrainingGroup-list';
const BATCH_CHANGE_STATUS_FOR_CPT = '/licensingCpt/updateRegisterStatus';

const LOAD_BROKER_REVIEW_INFO = '/pinpoint-college/select-sell-side-meeting';
const ADD_OR_EDIT_BROKER_REVIEW = '/entity-account/addOrEditUserAccess';
const COP_ALL_BROKER_REVIEW = '/entity-account/copAllUserAccess';
const DEL_BROKER_REVIEW = '/entity-account/delUserAccess';
const BROKER_REVIEW_UPLOAD_URL = '/entity-account/upload-user-access';
const BROKER_REVIEW_DOWNLOAD_URL = '/entity-account/download-user-access';
const UrlConfig = {
  /*
    Auth
  */
  
  // Login
  LOGIN,
  LOGOUT,
  GET_HIERARCHY_MENUS,

  /*
    Approval Request
  */

  // Banned Trade Request
  QUERY_BANNED_TRADE_REQUEST,
  APPROVE_BANNED_TRADE_REQUEST,
  REJECT_BANNED_TRADE_REQUEST,
  FEEDBACK_BANNED_TRADE_REQUEST,
  UPDATED_BANNED_TRADE_REQUEST,

  // Personal Trade
  QUERY_PERSONAL_TRADE,

  APPROVE_PERSONAL_TRADE,
  FINAL_APPROVE_PERSONAL_TRADE,
  REJECT_PERSONAL_TRADE,
  
  FEEDBACK_PERSONAL_TRADE,
  QUERY_PERSONAL_TRADE_OPERATE_RECORD,
  PERSONAL_TRADE_FIELD_HISTORY,

  NEW_PERSONAL_TRADE,
  UPDATED_PERSONAL_TRADE,
  BATCH_DELETED_PERSONAL_TRADES,
  COPIED_PERSONAL_TRADE,
  RECOGNIZE_PERSONAL_TRADE_REQUEST,

  LOAD_MONTHLY_REPORT,
  SEND_PA_DEALING_APPLY_REPORT_EMAIL,
  EXPORT_PA_DEALING_DAILY_REPORT,

  // 附件操作
  UPLOAD_PERSONAL_TRADE_ATTACHMENT,
  PREVIEW_PERSONAL_TRADE_ATTACHMENT,
  DOWNLOAD_PERSONAL_TRADE_ATTACHMENT,

  // Personal Holding
  QUERY_PERSONAL_HOLDING,
  APPROVE_PERSONAL_HOLDING,
  REJECT_PERSONAL_HOLDING,
  FEEDBACK_PERSONAL_HOLDING,

  NEW_PERSONAL_HOLDING_REPORT,
  UPDATED_PERSONAL_HOLDING_REPORT,
  BATCH_DELETED_PERSONAL_HOLDING_REPORTS,

  // Personal Account
  QUERY_PERSONAL_ACCOUNT_LIST,
  PERSONAL_ACCOUNT_DOWNLOAD_URL,
  PERSONAL_ACCOUNT_UPLOAD_URL,
  SUBMIT_ADD_OR_EDIT_ACCOUNT,
  DEL_PERSONAL_ACCOUNT,
  CHANGE_PERSONAL_ACCOUNT_STATUS,
  SUBMIT_ADD_OR_EDIT_HOLDING,
  DEL_PERSONAL_HOLDING,

  // 管理端新增/更新/删除 PersonalAccount
  NEW_PERSONAL_ACCOUNT_ON_ADMIN_END,
  UPDATED_PERSONAL_ACCOUNT_ON_ADMIN_END,
  BATCH_DELETE_PERSONAL_ACCOUNT_ON_ADMIN_END,

  // PersonalAccount 的提交历史
  PERSONAL_ACCOUNT_FIELD_HISTORY,

  // 管理端更新PersonalHolding
  NEW_PERSONAL_HOLDING_ON_ADMIN_END,
  UPDATED_PERSONAL_HOLDING_ON_ADMIN_END,
  BATCH_DELETE_PERSONAL_HOLDING_ON_ADMIN_END,

  // 接受/拒绝 PersonalAccountRequest
  ACCEPT_PERSONAL_ACCOUNT_REQUEST,
  REJECT_PERSONAL_ACCOUNT_REQUEST,

  // 邮件发送记录
  ACCOUNT_REPORT_PLAN_RECORD_LIST,

  // Gift Hospitality Request
  QUERY_GIFT_HOSPITALITY_REQUEST,
  APPROVE_GIFT_HOSPITALITY_REQUEST,
  REJECT_GIFT_HOSPITALITY_REQUEST,
  FEEDBACK_GIFT_HOSPITALITY_REQUEST,
  UPLOAD_GIFT_HOSPITALITY_ATTACHMENT,
  DOWNLOAD_GIFT_HOSPITALITY_ATTACHMENT,

  /*
    Compliance Rule
  */

  // Restricted Security Request
  LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST,
  APPROVE_RESTRICTED_SECURITY_REQUEST,
  REJECT_RESTRICTED_SECURITY_REQUEST,
  FEEDBACK_RESTRICTED_SECURITY_REQUEST,
  LOAD_RESTRICTED_SECURITY_OPERATION_RECORD,
  UPDATE_RESTRICTED_SECURITY_REQUEST,
  ADD_RESTRICTED_SECURITY_REQUEST,

  // Restricted Security
  LOAD_SUBMITTED_RESTRICTED_SECURITIES,
  NEW_RESTRICTED_SECURITY_BY_ADMIN,
  UPDATE_RESTRICTED_SECURITY_BY_ADMIN,
  BATCH_DELETE_RESTRICTED_SECURITY_BY_ADMIN,

  ON_LIST_RESTRICTED_SECURITY_BY_ADMIN,
  OFF_LIST_RESTRICTED_SECURITY_BY_ADMIN,

  // Restricted Security Attachment
  UPLOAD_RESTRICTED_SECURITY_ATTACHMENT,
  PREVIEW_RESTRICTED_SECURITY_ATTACHMENT,
  DOWNLOAD_RESTRICTED_SECURITY_ATTACHMENT,

  // Related Company Price Report
  GENERATE_RELATED_COMPANY_PRICE_REPORT,
  LOAD_RELATED_COMPANY_PRICE_REPORT,

  // Restricted Rule
  QUERY_RESTRICTED_RULES,
  NEW_RESTRICTED_RULE,
  UPDATED_RESTRICTED_RULE,
  BATCH_DELETED_RESTRICTED_RULES,

  // Restricted Asset
  QUERY_RESTRICTED_ASSET,
  NEW_RESTRICTED_ASSET,
  UPDATED_RESTRICTED_ASSET,
  DELETED_RESTRICTED_ASSET,
  BATCH_DELETED_RESTRICTED_ASSET,


  // Restricted Countries
  QUERY_RESTRICTED_COUNTRIES,
  NEW_RESTRICTED_COUNTRY,
  UPDATED_RESTRICTED_COUNTRY,
  DELETED_RESTRICTED_COUNTRY,
  BATCH_DELETED_RESTRICTED_COUNTRY,


  // Restricted Sectors
  QUERY_RESTRICTED_SECTORS,
  NEW_RESTRICTED_SECTOR,
  UPDATED_RESTRICTED_SECTOR,
  DELETED_RESTRICTED_SECTOR,
  BATCH_DELETED_RESTRICTED_SECTOR,

  // SFC Short Interest
  QUERY_SFC_SHORT_INTEREST,

  // PB Short Interest Rate
  QUERY_PB_SHORT_INTEREST_RATE,

  /*
    Questionnaire
  */
  QUERY_QUESTIONNAIRES,
  QUERY_QUESTIONS,
  QUERY_ANSWERS,
  SELECT_ANSWEREES,
  QUERY_TODO_QUESTIONNAIRE,
  SUBMIT_QUESTIONNAIRE_ANSWER,
  SAVE_QUESTIONNAIRE_ANSWER,


  QUERY_MEETINGANDTRANINGSUB,
  SUBMIT_MEETINGANDTRANINGSUB_ANSWER,
  SALE_MEETINGANDTRANINGS,



  NEW_QUESTIONNAIRE,
  UPDATED_QUESTIONNAIRE,
  COPIED_QUESTIONNAIRE,
  DELETED_QUESTIONNAIRE,
  BATCH_DELETED_QUESTIONNAIRE,

  NEW_QUESTION,
  UPDATED_QUESTION,
  DELETED_QUESTION,
  BATCH_DELETED_QUESTION,

  UPLOAD_QUESTIONNAIRE_ATTACHMENT,
  DOWNLOAD_QUESTIONNAIRE_ATTACHMENT,

  LOAD_QUESTIONNAIRE_RESULTS,
  LOAD_QUESTIONNAIRE_RESULT_DETAIL,
  ACCEPT_QUESTIONNAIRE_RESULT,
  REJECT_QUESTIONNAIRE_RESULT,
  REMIND_QUESTIONNAIRE_EMAIL,
  UPDATE_QUESTIONNAIRE_SCORE,
  LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY,


  QUERY_LICENSINGFORM,
  // QUERY_QUESTIONS,
  // QUERY_ANSWERS,
  // SELECT_ANSWEREES,
  // QUERY_TODO_QUESTIONNAIRE,
  // SUBMIT_QUESTIONNAIRE_ANSWER,
  // SAVE_QUESTIONNAIRE_ANSWER,
  QUERY_TODO_LICENSINGFORM,
  SUBMIT_LICENSINGFORM_ANSWER,
  SAVE_LICENSINGFORM_ANSWER,

  




  NEW_LICENSINGFORM,
  UPDATED_LICENSINGFORM,
  COPIED_LICENSINGFORM,
  DELETED_LICENSINGFORM,
  BATCH_DELETED_LICENSINGFORM,

  NEW_ITEM,
  UPDATED_ITEM,
  BATCH_DELETED_ITEM,
  // UPDATED_QUESTION,
  // DELETED_QUESTION,
  // BATCH_DELETED_QUESTION,

  // UPLOAD_QUESTIONNAIRE_ATTACHMENT,
  // DOWNLOAD_QUESTIONNAIRE_ATTACHMENT,

  // LOAD_QUESTIONNAIRE_RESULTS,
  // LOAD_QUESTIONNAIRE_RESULT_DETAIL,
  // ACCEPT_QUESTIONNAIRE_RESULT,
  // REJECT_QUESTIONNAIRE_RESULT,
  // REMIND_QUESTIONNAIRE_EMAIL,
  // UPDATE_QUESTIONNAIRE_SCORE,
  // LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY,


  // Entity Account
  QUERY_FUND_LIST,
  QUERY_BOOK_LIST,
  QUERY_COUNTERPARTY_LIST,
  QUERY_CUSTODIAN_LIST,
  QUERY_CUSTODIAN_ACCOUNT_LIST,
  LOAD_USER_ACCESS_INFO,
  ADD_OR_EDIT_USER_ACCESS,
  COP_ALL_USER_ACCESS,
  DEL_USER_ACCESS,
  USER_ACCESS_UPLOAD_URL,
  USER_ACCESS_DOWNLOAD_URL,
  MEETING_AND_TRAINING_DOWNLOAD_URL,
  MEETING_AND_TRAINING_UPLOAD_URL,




  LOAD_RATING_MANAGEMENT_INFO,
  ADD_OR_EDIT_RATING_MANAGEMENT,
  COP_ALL_RATING_MANAGEMENT,
  DEL_RATING_MANAGEMENT,
  RATING_MANAGEMENT_UPLOAD_URL,
  RATING_MANAGEMENT_DOWNLOAD_URL,
  /*
    Reference Data
  */
  // User Account
  QUERY_USER_ACCOUNTS,

  // Fund Book Data
  QUERY_FUND_BOOK_DATA,

  /*
    Security
  */
  QUERY_SECURITY_TICKER,
  QUERY_BBG_TICKERS,
  QUERY_ALL_BBG_TICKERS,
  QUERY_ASSET_INST_CLASS,
  QUERY_COUNTRY_CODES,
  QUERY_SECTOR_CODES,
  QUERY_EXCHANGE_CODE,

  /*
    Currency
  */
  QUERY_CURRENCY_CODES,
  /*
    Short Stock
  */
  LOAD_SHORT_STOCK,
  SYNC_SHORT_STOCK,

  /*
    Exchange Rate
  */
  LOAD_EXCHANGE_RATE_LIST,
  SYNC_EXCHANGE_RATE,

  /*
    13F Report
  */
  LOAD_13F_REPORT_LIST,
  SYNC_13F_REPORT,

  /*
    13F Long Position Report
  */
  LOAD_13F_LONG_POS_REPORT,
  GENERATE_13F_LONG_POS_REPORT,
  CHECK_13F_LONG_POS_REPORT,
  REPORT_13F_LONG_POS_REPORT,
  REPORT_13F_LONG_POS_SUMMARY_REPORT,
  DOWNLOAD_13F_LONG_POS_REPORT,

  /*
    Short Position Report
  */
  LOAD_SHORT_POSITION_REPORT,
  GENERATE_SHORT_POSITION_REPORT,
  DOWNLOAD_SHORT_POSITION_REPORT,
  SHORT_POSITION_REPORT_CHECK_RESULT,
  REPORT_SHORT_POSITION_REPORT,

  /*
    Fund Level Report
  */
  LOAD_FUND_LEVEL_REPORT,
  LIST_FUND_POS_GROUP_INFO,
  GENERATE_FUND_LEVEL_REPORT,

  /*
    Trades Review
  */
  QUERY_TRADES_REVIEW,
  QUERY_TRADES_REVIEW_REPORT,

  // download pdf
  DOWNLOAD_QUESTIONNAIRE_RESULT_PDF,

  // 30天持仓相关预检查
  QUERY_30_DAY_HOLDING_CHECK_INFO,

  // licensing
  LOAD_LICENSING_INFO,
  LICENSING_ATT_DOWNLOAD_URL,
  LOAD_LICENSE_BASIC_INFO_OPTIONS,
  LICENSING_EXAM_CPT_PROOF_UPLOAD_URL,
  ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD,
  BATCH_DEL_LICENSE_INFO,
  SUBMIT_EDIT_CPT_RCD,
  QUERY_OPERATION_RCD,

  // Expense Summary
  LOAD_EXPENSE_SUMMARY_INFO,
  ADD_OR_EDIT_EXPENSE_SUMMARY,
  DEL_EXPENSE_SUMMARY,

  LOAD_EXPENSE_SUMMARY_INFONEW,

  // Reporting Summary
  LOAD_REPORTING_SUMMARY_INFO,
  INSERT_REPORTING_SUMMARY,
  UPDATE_REPORTING_SUMMARY,
  BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY,
  FINISH_REPORTING_SUMMARY_TASK,


  // Work Management
  LOAD_WORKING_MANAGEMENT_INFO,
  INSERT_WORKING_MANAGEMENT,
  UPDATE_WORKING_MANAGEMENT,
  DEL_WORKING_MANAGEMENT,
  BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT,
  FINISH_WORKING_MANAGEMENT_TASK,

  INSERT_WORKING_MANAGEMENTSUB,
  UPDATE_WORKING_MANAGEMENTSUB,

  // Portfolio Monitoring Rule
  LOAD_PORTFOLIO_MONITORING_RULE_INFO,
  INSERT_PORTFOLIO_MONITORING_RULE,
  UPDATE_PORTFOLIO_MONITORING_RULE,
  DEL_PORTFOLIO_MONITORING_RULE,
  BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE,
  FINISH_PORTFOLIO_MONITORING_RULE_TASK,
  

  // common
  GET_FX_BY_NEAREST_DATE,
  QUERY_OPERATION_RECORD,
  QUERY_HISTORY_UPLOAD,

  // pendingTasks
  QUERY_PENDING_TASKS_NUM,
  QUERY_PENDING_TASKS_LIST,
  IGNORE_DEIGNORE_PENDING_TASKS,

  // General Agreement
  LOAD_GENERAL_AGREEMENT_INFO,
  GENERAL_AGREEMENT_UPLOAD_URL,
  GENERAL_AGREEMENT_DOWNLOAD_URL,
  INSERT_AGREEMENT_INFO,
  UPDATE_AGREEMENT_INFO,
  SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS,

  // Pinpoint Group - Group Structure
  QUERY_GROUP_STRUCTURE,
  GROUP_STRUCTURE_DOWNLOAD_URL,
  GROUP_STRUCTURE_UPLOAD_URL,
  SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE,
  SUBMIT_DEL_GROUP_STRUCTURE,

  // Pinpoint Group - Element Type Manage
  QUERY_ELEMENT_TYPE,
  SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE,
  DEL_ELEMENT_TYPE,
  SUBMIT_ADD_OR_EDIT_FIELD,
  DEL_ELEMENT_TYPE_FIELD,

  // Pinpoint Group - Element Manage
  QUERY_ELEMENT,
  SUBMIT_ADD_OR_EDIT_ELEMENT,
  DEL_ELEMENT,
  DEL_PROPS,

  //month report 
  LOAD_MONTHLY_REPORT_LIST,
  LOAD_MONTHLY_REPORT_DETAIL,
  SAVE_MONTHLY_REPORT,
  DEL_MONTHLY_REPORT,
  MONTHLY_REVIEW_UPLOAD_ATTACHMENT,
  MONTHLY_REVIEW_DOWNLOAD_ATTACHMENT,

  LOAD_RESTRICTED_SECURITIES_RULE,
  ADD_RESTRICTED_SECURITIES_RULE,
  SAVE_RESTRICTED_SECURITIES_RULE,


  LOAD_MEETING_AND_TRAINING_INFO,
  ADD_OR_EDIT_MEETING_AND_TRAINING,
  COP_ALL_MEETING_AND_TRAINING,
  DEL_MEETING_AND_TRAINING,
  SELECT_MEETINGANSWEREES,
  UPDATED_NOTE,



  LOAD_MEETING_AND_TRAINING_INFO_SUB,
  LOAD_MEETING_AND_TRAINING_GROUP_INFO,
  ADD_OR_EDIT_MEETING_AND_TRAINING_GROUP,
  DEL_MEETING_AND_TRAINING_GROUP,
  LOAD_MEETING_AND_TRAINING_INFO_SUB_FORIN,
  LOAD_MEETING_AND_TRAINING_INFOTO_SUB,
  LOAD_MEETING_AND_TRAINING_INFO_SUB_BY_MEETINGID,
  LOAD_MEETING_AND_TRAINING_APPLYRECORD_INFO,
  UPDATE_MEETING_AND_TRAINING_APPLYRECORD,
  LOAD_ROTATION_SCORE_FORIN,
  UPDATE_ROTATION_SCORE_FORIN,

  LOAD_MEETING_AND_TRAINING_CALENDER_PINPOINT,
  LOAD_MEETING_AND_TRAINING_CALENDER_PERSONAL,

  LOAD_MEETING_AND_TRAINING_CALENDER_LIST_BROKER,
  LOAD_MEETING_AND_TRAINING_CALENDER_DETAILE,
  LOAD_MEETING_AND_TRAINING_CALENDER_UPDATE,
  // Work Management
  LOAD_PRESONAL_INFO_INFO,
  INSERT_PRESONAL_INFO,
  UPDATE_PRESONAL_INFO,
  DEL_PRESONAL_INFO,
  BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO,
  // FINISH_PRESONAL_INFO_TASK,
  ACCEPT_PRESONAL_INFO_RESULT,
  REJECT_PRESONAL_INFO_RESULT,
  REMIND_PRESONAL_INFO_EMAIL,



  LOAD_REGISTER_INFO,
  INSERT_REGISTER,
  UPDATE_REGISTER,
  DEL_REGISTER,
  BATCH_CHANGE_STATUS_FOR_REGISTER,


 //cpt
 SELECT_REGISTERINFO_BY_NAME,

  LOAD_CPT_INFO,
  INSERT_CPT,
  UPDATE_CPT,
  DEL_CPT,
  BATCH_CHANGE_STATUS_FOR_CPT,


  LOAD_BROKER_REVIEW_INFO,
  ADD_OR_EDIT_BROKER_REVIEW,
  COP_ALL_BROKER_REVIEW,
  DEL_BROKER_REVIEW,
  BROKER_REVIEW_UPLOAD_URL,
  BROKER_REVIEW_DOWNLOAD_URL,

  
}

export default UrlConfig;
